export default function animateInView() {
  const elementsToObserve = document.querySelectorAll(".animate-in-view");

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
          observer.unobserve(
            entry.target
          ); /* Unobserve after the first time it's in view */
        }
      });
    },
    { threshold: 0.1 }
  );

  elementsToObserve.forEach((element) => {
    observer.observe(element);
  });
}
